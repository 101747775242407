<template>
    <default-layout>

        <div class="d-flex flex-wrap w-100 px-sm-4" style="background-color:var(--spainhn-rojo-corporativo)">
            <div class="py-2 px-2 ms-auto">
                <a href="/clientes/reservas" class="link-light"><small>{{ $t('default.CLIENTES_RESERVAS') }}</small></a>
            </div>

            <div class="py-2 border-start px-2">
                <a href="/clientes/cambiaclave" class="link-light"><small> {{ $t('default.CLIENTES_PASSWORD_RESET') }} </small></a>
            </div>

            <div class="py-2 border-start px-2">
                <a href="/clientes/cambiaemail" class="link-light"><small> {{ $t('default.CLIENTES_EMAIL_CHANGE') }} </small></a>
            </div>
            <div class="py-2 border-start px-2">
                <a href="/clientes/editar" class="link-light"><small> {{ $t('default.CLIENTES_EDIT_PROFILE') }} </small></a>
            </div>
            <div class="py-2 border-start px-2 me-auto">
                <a href="#" class="link-light" @click="logout"><small> {{ $t('default.CLIENTES_LOGOUT') }} </small></a>
            </div>

        </div>
        <div class="container bg-white my-5 border py-5">
            <b-alert :show="updateConfirm" dismissible variant="success">Datos actualizados</b-alert>
            
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <p class="spainhn-subtitle-2-black">{{ $t( 'default.CLIENTES_EDIT_PROFILE_TITLE' ) }}</p>
                        </div>
                        <div class="col-7 mx-auto">
                            <p class="spainhn-text">{{ $t('default.CLIENTES_EDIT_PROFILE_ABSTRACT') }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-8 mx-auto">
                    <b-form-group>
                        <b-form-input v-model="nombre" class="my-1" :placeholder="$t('default.CLIENTES_EDIT_NAME')"></b-form-input>
                        <b-form-input class="my-1" v-model="nif" :placeholder="$t('default.CLIENTES_EDIT_DNI')"></b-form-input>
                        <b-form-input class="my-1" v-model="address" :placeholder="$t('default.CLIENTES_EDIT_ADDRESS')"></b-form-input>
                        <b-form-input class="my-1" v-model="codPostal" :placeholder="$t('default.CLIENTES_EDIT_COD_POSTAL')"></b-form-input>
                        
                        <p class="mt-2 mb-0 spainhn-text-small text-start"> {{ $t('default.CLIENTES_EDIT_PROVINCIA') }} </p>
                        <b-form-select :options="provincias" v-model="provinciaCliente" @onChange="setToCountry" required ref="provincia" class="mt-2 py-2 d-block"/>
                        
                        <p class="mt-2 mb-0 spainhn-text-small text-start" v-bind:class="{'d-none':provinciaCliente != 'out'}"> {{ $t('default.CLIENTES_EDIT_COUNTRY') }} </p>
                        <country-select class="d-block py-2" :blackList="['ES']" :usei18n="false" v-model="country" :country="country" topCountry="GB" v-bind:class="{'d-none':provinciaCliente != 'out'}"/>

                        <b-form-input class="my-1" type="date" v-model="birthday" :placeholder="$t('default.CLIENTES_EDIT_BIRTHDAY')"></b-form-input>
                        <b-form-input class="my-1" v-model="telefono" :placeholder="$t('default.CLIENTES_EDIT_PHONE')"></b-form-input>
                    </b-form-group>
                </div>

                <div class="col-12 col-lg-8 mx-auto">
                    
                    <div class="d-flex flex-wrap justify-content-center pt-3">
                        <div class="col-12 mx-auto">
                            <div class="row mx-auto">
                                <div class="col-6 mx-auto">
                                    <mdb-btn color="indigo" class="spainhn-button mx-auto" size="lg" :disabled="sending" @click="updateClientInformation">{{ $t('default.CLIENTES_CHANGE_PASSWORD_BUTTON') }}</mdb-btn>
                                </div>
                                <div class="col-6">
                                    <mdb-btn color="indigo"  class="spainhn-button mx-auto" size="lg" @click="() => $router.push('/clientes')" :disabled="sending">{{ $t('default.CLIENTES_CANCEL_PASSWORD_BUTTON') }}</mdb-btn>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </default-layout>    
</template>

<script>
import ApiService from '../services/ApiService';
export default {
    name: "Clientes",
    components:{

    },
    data(){
        return{
            provinciaCliente: 'out',
            nombre: null,
            address: null,
            telefono: null,
            nif:null,
            codPostal: null,
            country: null,
            birthday: null,
            sending: false,
            provincias: [],
            updateconfirm: false,
            confirmMessage: "",
            clienteId: null
        }
    },
    methods:{
        logout(){
            this.$store.dispatch('removeAccessToken')

            if(this.$route.name == 'clientes'){
                this.$router.push('/')
            }
            console.log(this.$route);
        },
        // Selecciona como país España
        setToCountry(){
            let $vm = this;
            if($vm.provinciaCliente != "out"){
                $vm.country = "ES";
            }
        },
        requestClientInformation(){
            let $vm = this;
            $vm.sending = true;
            let accessToken = this.$store.getters.getAccessToken;

            ApiService.getClientInformation(accessToken)
                .then( res => {
                    $vm.sending = false;
                    console.log( res.data );
                    $vm.nombre = res.data.nombre;
                    $vm.address = res.data.direccion;
                    $vm.telefono = res.data.telefono;
                    $vm.nif = res.data.nif;
                    $vm.codPostal = res.data.codPostal;
                    $vm.country = res.data.paisCode;
                    $vm.birthday = res.data.fechaNacimiento.split("T")[0];
                    $vm.clienteId = res.data._id;
                    $vm.provinciaCliente = res.data.provincia

                    if($vm.country == 'ES'){
                        $vm.provinciaCliente = $vm.codPostal.slice(0, 2)
                        console.log($vm.provinciaCliente)
                    }
                    else{
                        $vm.provinciaCliente = 'out';
                    }
                })
                .catch( err=> {
                    $vm.sending = false;
                    console.log( err );
                })
        },
        updateClientInformation(){
            let $vm = this;
            $vm.sending = true;

            let accessToken = this.$store.getters.getAccessToken;
            console.log($vm.birthday)
            if($vm.provinciaCliente != 'out'){
                $vm.country = "ES";
            }

            let body = {
                id: $vm.clienteId,
                nombre: $vm.nombre,
                fechaNacimiento: $vm.birthday,
                codPostal: $vm.codPostal,
                nif: $vm.nif,
                paisCode: $vm.country,
                telefono: $vm.telefono,
                provincia: $vm.provinciaCliente,
                direccion: $vm.address
            }
            // Actualizamos los datos del usuario en el servidor
            ApiService.updateClientInformation(accessToken, body)
                .then( res => {
                    console.log(res)
                    $vm.sending = false;
                    $vm.updateConfirm = true;
                    $vm.confirmMessage = res.data.message;
                })
                .catch(err => {
                    $vm.sending = false;
                    $vm.updateConfirm = true;
                    if(err.response.status == 400){
                        console.log(err.response.data);
                    }
                })
        }

    },
    async mounted(){
        await this.$store.dispatch('fetchAccessToken');
        let accessToken = this.$store.getters.getAccessToken;
        let $vm = this;
        
        $vm.provincias = this.$store.getters.provincias;
        // Revisamos si el token está aun activo
        ApiService.validateClientToken(accessToken)
            .then( res => {
                console.log(res);
                // Pedimos la información de este cliente
                $vm.requestClientInformation();
            })
            .catch( async err => {
                if(err.response.status == 401){
                    console.log(err.response.data);
                    await this.$store.dispatch('removeAccessToken');
                    this.$router.push('/clientes/login');
                }
            })
    }
}
</script>
